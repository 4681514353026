<template>
  <nav class="flex pl-2 pr-3" v-if="header !== null">
    <ul class="flex justify-between items-center w-full">
      <template v-for="(item, index) in header.menu">
        <template v-if="item.__component == 'menu.link'">
          <router-link
            :to="item.url"
            tag="li"
            class="cursor-pointer p-3 md:p-2"
            :key="index"
          >
            {{ item.label }}
          </router-link>
        </template>
        <template v-if="item.__component == 'menu.dropdown'">
          <li
            class="cursor-pointer p-3"
            :key="index"
            @mouseover="mouseEnter(index)"
            @mouseout="mouseOut"
          >
            <span>
              {{ item.label }}
              <img class="inline w-3" src="@/assets/svg/arrow-black-down.svg" />
            </span>
            <transition-expand>
              <ul
                class="absolute bg-primary border-b border-black p-3"
                v-show="hoverIndex == index"
                @mouseover="mouseEnter(index)"
              >
                <router-link
                  :to="item.url"
                  tag="li"
                  class="pb-3 last:pb-0"
                  v-for="(item, index) in item.link"
                  :key="index"
                >
                  {{ item.label }}
                </router-link>
              </ul>
            </transition-expand>
          </li>
        </template>
        <template v-if="item.__component == 'menu.socials'">
          <ul class="hidden lg:flex flex-row" :key="index">
            <li
              class="pl-1 pr-1 first:pl-0 last:pr-3"
              v-for="(social, index) in item.socials"
              :key="index"
            >
              <a :href="social.url" target="_blank" rel="nofollow">
                <unicon
                  :name="social.type"
                  :width="'16'"
                  :height="'16'"
                ></unicon>
              </a>
            </li>
          </ul>
        </template>
      </template>
    </ul>
  </nav>
</template>

<script>
import TransitionExpand from "@/components/transitions/TransitionExpand.vue";

export default {
  name: "Menu",
  props: ["header"],
  components: {
    TransitionExpand,
  },
  data() {
    return {
      hoverIndex: null,
      mouseTimeout: null,
    };
  },
  methods: {
    mouseEnter(index) {
      if (this.mouseTimeout !== null) {
        clearTimeout(this.mouseTimeout);
      }
      this.hoverIndex = index;
    },
    mouseOut() {
      this.mouseTimeout = setTimeout(() => {
        this.hoverIndex = null;
      }, 400);
    },
  },
};
</script>